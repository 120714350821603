require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('jquery')

import 'bootstrap'
import 'controllers'
import '@chenfengyuan/datepicker/dist/datepicker.min.js';
import '@chenfengyuan/datepicker/i18n/datepicker.es-ES.js';
import 'stylesheets/application'
