import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "file", "image" ]

  upload(event) {
    let floorId = this.element.getAttribute("data-blueprints-id")
    let reader = new FileReader()
    reader.onload = () => {
      let params = new URLSearchParams({ "base64": reader.result }).toString()
      Rails.ajax({
        type: "PATCH",
        url: "/portal/floors/" + floorId + ".json",
        data: params,
        success: (res) => {
          this.imageTarget.src = reader.result
        },
        error: (res) => {
          console.log('error', res)
          alert('La imagen no pudo ser asignada correctamente. Refresque la página e intente de nuevo.')
        }
      })
    }
    reader.readAsDataURL(this.fileTarget.files[0])
  }
}
