import { Controller } from "stimulus"

export default class extends Controller {
  show(event) {
    // Desactivar todos los niveles
    let floors = document.getElementsByClassName("floors-filter")
    for (let floor of floors) {
      floor.classList.toggle("active", false)
      floor.querySelector(".forward-icon").classList.toggle("display-none", false)
      floor.querySelector(".downward-icon").classList.toggle("display-none", true)
    }

    // Desactivar todas las viviendas
    let properties = document.getElementsByClassName("properties-option")
    for (let property of properties) {
      property.classList.toggle("display-none", true)
    }

    // Activar el nivel que acaba de ser seleccionado
    let fila = event.target.closest("tr")
    fila.classList.toggle("active", true)
    fila.querySelector(".forward-icon").classList.toggle("display-none", true)
    fila.querySelector(".downward-icon").classList.toggle("display-none", false)

    // Actualizar headers
    document.getElementById("selected-property").innerHTML = ""

    // Necesitamos también eliminar la tabla en caso de que exista
    let raoTable = document.getElementById("property-rao-table")
    if(raoTable != undefined) {
      raoTable.remove()
    }

    // Mostrar las viviendas dentro del nivel seleccionado
    let selectedFloorClass = "properties-option-" + this.data.get("floor")
    properties = document.getElementsByClassName(selectedFloorClass)
    for (let property of properties) {
      property.classList.toggle("display-none", false)
    }
  }
}