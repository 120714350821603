import { Controller } from "stimulus"

export default class extends Controller {
  show(event) {
    // Desactivar todas las visitas visibles
    let visits = document.getElementsByClassName("visits-filter")
    for (let visit of visits) {
      visit.classList.toggle("active", false)
      visit.querySelector(".forward-icon").classList.toggle("display-none", false)
      visit.querySelector(".downward-icon").classList.toggle("display-none", true)
    }

    // Activar la que acaba de ser seleccionada
    let fila = event.target.closest("tr")
    fila.classList.toggle("active", true)
    fila.querySelector(".forward-icon").classList.toggle("display-none", true)
    fila.querySelector(".downward-icon").classList.toggle("display-none", false)

    // Placeholder safetey check TODO
    if(this.data.get("url") == undefined) { return }

    // Actualizar headers
    document.getElementById("selected-visit").innerHTML = "<strong>Visita:</strong> " + fila.children[0].innerHTML
    document.getElementById("selected-property").innerHTML = ""

    // Necesitamos también eliminar la tabla en caso de que exista
    let raoTable = document.getElementById("property-rao-table")
    if(raoTable != undefined) {
      raoTable.remove()
    }

    // Solicitar el render de la lista de niveles/viviendas
    fetch(this.data.get("url"))
      .then(response => {
        if(response.redirected == false) {
          return response.text()
        }
      })
      .then(html => {
        if(html == undefined) {
          Turbolinks.visit("/portal/login")
        } else {
          document.getElementById("avances-viviendas").innerHTML = html
        }
      })
  }
}