import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'section', 'content' ]

  connect() {
    this.showIndex(0)
  }

  show(event) {
    const targetId = event.target.id;
    this.sectionTargets.forEach((el, i) => {
      el.parentElement.classList.toggle("active", el.id == targetId)
    })
    this.contentTargets.forEach((el, i) => {
      const isVisible = el.classList.contains(targetId)
      el.classList.toggle("display-none", !isVisible)
    })
  }

  showIndex(index) {
    this.sectionTargets.forEach((el, i) => {
      el.classList.toggle("active", index == i)
    })
    this.contentTargets.forEach((el, i) => {
      el.classList.toggle("display-none", index != i)
    })
  }
}
