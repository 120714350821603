import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "email" ]

  remove(event) {
    if(document.getElementsByClassName("email-group").length > 1) {
      event.target.closest("div").remove()
    } else {
      event.target.closest("div").children[0].value = ""
    }
  }

  add() {
    let list = document.getElementById("email-list")
    let node = document.createElement("div")
    node.setAttribute("class", "email-group d-flex margin-top-25")
    node.innerHTML = "<input type=\"text\" name=\"emails[]\" id=\"emails_\" value=\"\" placeholder=\"email@example.com\" class=\"form-control\"><button data-action=\"emails#remove\" class=\"btn btn-danger edit-btn pl-4 pr-4 ml-3 mr-3 white-font-btn delete-btn\">X</button>"
    list.appendChild(node)
  }
}
