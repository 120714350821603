import Calendar from 'js-year-calendar';
import 'js-year-calendar/locales/js-year-calendar.es';

Date.prototype.toUrlParam = function () {
  //If month/day is single digit value add perfix as 0
  function AddZero(obj) {
    obj = obj + '';
    if (obj.length == 1)
      obj = "0" + obj
    return obj;
  }

  var output = "";
  output += this.getFullYear();
  output += AddZero(this.getMonth() + 1);
  output += AddZero(this.getDate());

  return output;
}

function editEvent(event) {
  $.ajax({
    url: `calendars/${event.date.toUrlParam()}/edit.html`,
    dataType: 'text',
    success: function (html) {
      $('#event-body').html(html);
      $('#event-modal').modal('show');
      $('.datepicker-here').each(function () {
        $(this).datepicker({
          language: 'es-ES',
          format: 'dd/mm/yyyy',
          zIndex: 1051
        });
      });
    }
  });
}

function loadHolidays(calendar) {
  $.ajax({
    url: `${window.location.origin}/portal/holidays.json`,
    type: 'GET',
    data: 'json',
    success: function (data) {
      if (data)
        calendar.setDisabledDays(data.map(holiday => (new Date(holiday))));
    }
  });
}

function createCalendar() {
  return new Calendar('.calendar', {
    language: 'es',
    dataSource: function (year) {
      return fetch(`${window.location.origin}/portal/scheduled_visits.json?year=${year}`)
        .then(result => result.json())
        .then(result => {
          if (result) {
            return result.map(scheduled_visit => ({
              startDate: new Date(scheduled_visit.date),
              endDate: new Date(scheduled_visit.date),
              name: scheduled_visit.route.name,
              color: scheduled_visit.route.color,
              projects: scheduled_visit.route.projects,
              holiday: scheduled_visit.holiday
            }));
          }

          return [];
        });
    },
    mouseOnDay: function (e) {
      if (e.events.length > 0) {
        var content = '';

        for (var i in e.events) {
          if (e.events[i].holiday) {
            content += '<div class="calendar-tooltip-content">'
              + '<div class="route-name">'
              + `<p style="color: ${e.events[i].color};">`
              + '<strong>' + e.events[i].name + '</strong>'
              + '</p>'
              + '</div>'
              + '</div>';

          } else {
            var projects_list = '';

            if (e.events[i].projects.length > 0) {
              for (var j in e.events[i].projects) {
                if (e.events[i].projects[j].total_status_updates >= 19) {
                  projects_list += `<li>${e.events[i].projects[j].name}</li> <p style="color: #CC0000; font-size: 12px">* Proyecto tiene 19 o más visitas en el año</p>`
                } else {
                  projects_list += `<li>${e.events[i].projects[j].name}</li>`
                }
              }
            } else {
              projects_list += 'Ruta no tiene proyectos asignados'
            }

            content += '<div class="calendar-tooltip-content">'
              + '<div class="route-name">'
              + `<p style="color: ${e.events[i].color};">`
              + '<strong>' + e.events[i].name + '</strong>'
              + '</p>'
              + '</div>'
              + '<ul>'
              + projects_list
              + '</ul>'
              + '</div>';
          }
        }

        $(e.element).popover({
          trigger: 'manual',
          container: 'body',
          html: true,
          content: content
        });

        $(e.element).popover('show');
      }
    },
    mouseOutDay: function (e) {
      if (e.events.length > 0) {
        $(e.element).popover('hide');
      }
    },
    clickDay: function (e) {
      editEvent(e);
    },
    disabledWeekDays: [0]
  });
}

function setModalCallbacks(calendar) {
  $('#event-modal').on('show.bs.modal', function () {
    $('form.route-form').on('ajax:success', function (event) {
      $('div.alert').hide();
      var message = "<div class='alert alert-success'>" + event.detail[0].notice + "</div>"
      $('form#route-form-' + event.detail[0].route_id).hide();
      $('div#form-container-' + event.detail[0].route_id).html(message);
    }).on('ajax:error', function (event) {
      $('div.alert').hide();
      var message = "<div class='alert alert-danger'>" + event.detail[0].error + "</div>"
      $('div#form-container-' + event.detail[0].route_id).prepend(message);
    });
  });

  $('#event-modal').on('hide.bs.modal', function (e) {
    calendar.setDataSource(function (year) {
      return fetch(`${window.location.origin}/portal/scheduled_visits.json?year=${year}`)
        .then(result => result.json())
        .then(result => {
          if (result) {
            return result.map(scheduled_visit => ({
              startDate: new Date(scheduled_visit.date),
              endDate: new Date(scheduled_visit.date),
              name: scheduled_visit.route.name,
              color: scheduled_visit.route.color,
              projects: scheduled_visit.route.projects,
              holiday: scheduled_visit.holiday
            }));
          }

          return [];
        });
    });
  })
}

$(document).on('turbolinks:load', function () {
  if (document.getElementById("calendar")) {
    $.fn.tooltip.Constructor.Default.whiteList['*'].push('style') // https://getbootstrap.com/docs/4.4/getting-started/javascript/#sanitizer

    const calendar = createCalendar();

    loadHolidays(calendar);

    setModalCallbacks(calendar);
  }
});