import { Controller } from "stimulus"

export default class extends Controller {
  show(event) {
    // Desactivar cualquier otra vivienda seleccionada
    let properties = document.getElementsByClassName("properties-option")
    for (let property of properties) {
      property.classList.toggle("active", false)
    }

    // Activar la vivienda que acaba de ser seleccionada
    let fila = event.target.closest("tr")
    fila.classList.toggle("active", true)

    // Solicitar el render de la lista de niveles/viviendas
    fetch(this.data.get("url"))
      .then(response => {
        if(response.redirected == false) {
          return response.text()
        }
      })
      .then(html => {
        if(html == undefined) {
          Turbolinks.visit("/portal/login")
        } else {
          document.getElementById("avance-partidas-viviendas").innerHTML = html
        }
      })
  }

  urbanization(event) {
    // Desactivar todos los niveles y viviendas seleccionadas
    let floors = document.getElementsByClassName("floors-filter")
    for (let floor of floors) {
      floor.classList.toggle("active", false)
      floor.querySelector(".forward-icon").classList.toggle("display-none", false)
      floor.querySelector(".downward-icon").classList.toggle("display-none", true)
    }

    let properties = document.getElementsByClassName("properties-option")
    for (let property of properties) {
      property.classList.toggle("active", false)
      property.classList.toggle("display-none", true)
    }

    // Activar la sección de urbanización que acaba de ser seleccionada
    let fila = event.target.closest("tr")
    fila.classList.toggle("active", true)

    // Solicitar el render de las partidas de urbanización
    fetch(this.data.get("url"))
      .then(response => {
        if(response.redirected == false) {
          return response.text()
        }
      })
      .then(html => {
        if(html == undefined) {
          Turbolinks.visit("/portal/login")
        } else {
          document.getElementById("avance-partidas-viviendas").innerHTML = html
        }
      })
  }
}