import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "role" ]

  update() {
    let userId = this.element.getAttribute("data-roles-id")
    let roleSelected = this.roleTarget.selectedOptions[0].value
    Rails.ajax({
      type: "PATCH",
      url: "/portal/users/" + userId + ".json",
      data: "role=" + roleSelected,
      success: () => {
        alert('El rol ' + this.roleTarget.selectedOptions[0].text + ' ha sido asociado al usuario correctamente.')
      },
      error: (response) => {
        alert('El rol ' + roleSelected + ' no pudo ser asignado al usuario seleccionado. Refresque la página e intente de nuevo.')
      }
    })
  }
}
